<template>
  <div style="width: 100%; height: 100%">
    <router-view />
  </div>
</template>

<script>
export default {}
</script>

<style>
  .v-main {
    background-color: white;
  }
</style>
